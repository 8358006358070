import apiimage from '../assets/images/CCXT/API-Keys.webp'
import apiimage1 from '../assets/images/CCXT/Create_API_Key.webp'
import BNB1 from '../assets/images/CCXT/BNB1.png'
import BNB2 from '../assets/images/CCXT/BNB2.png'
import BNB3 from '../assets/images/CCXT/BNB3.png'
import BNB4 from '../assets/images/CCXT/BNB4.png'
import BNBUS1 from '../assets/images/CCXT/binanceUS1.png'
import BNBUS2 from '../assets/images/CCXT/binanceUS2.png'
import BNBUS3 from '../assets/images/CCXT/binanceUS3.png'
import BPT from '../assets/images/CCXT/BPT.png'
import BPT2 from '../assets/images/CCXT/BPT2.png'
import BPT3 from '../assets/images/CCXT/BPT3.png'
import BPT4 from '../assets/images/CCXT/BPT4.png'
import BPT5 from '../assets/images/CCXT/BPT5.png'
import BYBIT1 from '../assets/images/CCXT/BYBIT1.png'
import BYBIT2 from '../assets/images/CCXT/BYBIT2.png'
import BYBIT3 from '../assets/images/CCXT/BYBIT3.png'
import BYBIT4 from '../assets/images/CCXT/BYBIT4.png'
import BYBIT5 from '../assets/images/CCXT/BYBIT5.png'
import BYBIT6 from '../assets/images/CCXT/BYBIT6.png'
import BYBIT7 from '../assets/images/CCXT/BYBIT7.png'
import BYBIT8 from '../assets/images/CCXT/BYBIT8.png'
import CPT from '../assets/images/CCXT/CPT.png'
import trailing_image from '../assets/images/trailing_image3.png'
export const telegramMsg = () => {

        let msg = `<p>
        <b>Step 1 :</b> Enter @Botfather in the search tab and choose the bot. <a target="_blank" href="https://prnt.sc/L_jEttmAZ56F">Check this</a><br>
        * Official Telegram bots have a blue checkmark beside their name.<br>        
    </p>
    <p>

    <b>Step 2 :</b> Click “Start” to activate BotFather bot. <a target="_blank" href="https://prnt.sc/CwouLGtf4qe0">Check this</a> <br>
        * In response, you receive a list of commands to manage bots.
    </p>

    <p>
        <b>Step 3 :</b> Choose or type the /newbot command and send it. <a target="_blank" href="https://prnt.sc/OCtNa1CZB5DA">Check this</a>
    </p>
    <p>
        <b>Step 4 :</b> Choose a name for your bot, and choose a username for your bot.  the bot can be found by its username in searches. The username must be unique and end with the word “bot.” <a target="_blank" href="https://prnt.sc/sYZ-InqQjheN">Check this</a><br>
        * After you choose a suitable name for your bot the bot is created. You will receive a message with a link to your bot t.me/<bot_username>, recommendations to set up a profile picture, description, and a list of commands to manage your new bot.
    </p>
    <p>
        <b>Step 5 :</b> To get Derisnap notification you need the token from Telegram. Copy the token value and paste it here(Token). <a target="_blank" href="https://prnt.sc/IWH4ftMSns2r">Check this</a>
    </p>
    `;
    return msg;
}

export const telegramIDMsg = () => {

    let msg = `<p>
    <b>Step 1 :</b> Enter @userinfobot in the search tab and choose the bot. <a target="_blank" href="https://prnt.sc/fk3HBGz_9GXT">Check this</a><br>    
</p>
<p>

<b>Step 2 :</b> By clicking Start, you’ve automatically entered “/start.” It will prompt the bot to display your user ID, as well as your selected first name, last name, and the language of choice. <a target="_blank" href="https://prnt.sc/DOFMvvQ-HuHk">Check this</a> <br>
    
</p>

<p>
    <b>Step 3 :</b> Copy your ID value and paste it here.
</p>
`;
return msg;
}

export const telegramChatIDMsg = () => {

    let msg = `
<p>
    <b>Step 1 :</b> Go to your group and click the add members icon. <a target="_blank" href="https://prnt.sc/_U6Q2fMa5pT2">Check this</a><br>
</p>

<p>
    <b>Step 2 :</b> Enter @username_to_id_bot in the search tab and choose the bot then add your group. <a target="_blank" href="https://prnt.sc/4k-0Sn4vBkG3">Check this</a><br>
</p>

<p>
    <b>Step 3 :</b> Copy chat ID and paste it here . <a target="_blank" href="https://prnt.sc/lsKB6sRNs1w8">Check this</a><br>
</p>
<p>
    <b>Step 4 :</b> Finally create your own group and add your bot in to your group using add members icon.
</p>
`;
return msg;
}
export const MinimumTakeProfit = () => {

    let msg = `
    <img src=${trailing_image} alt="Trailing Stop Price Percentage" style="width:100%;height:100%;" />
 <p>
    <b>Ex:</b> Trailing Take Profit Percentage = 10%, Trailing Stop Price Percentage = 5%.<br>
 </p>
 
 <p>
     <b>1.</b> When order entered into position with price as 600, then Trailing Take Profit will be 540 (-10% of 600) & Trailing Stop Price will be 570 (-5% of 600), that is Stop price = 570.<br>
 </p>
 
 <p>
     <b>2.</b>  When ASK price was in the range of 540 to 569, the Stop price will be 570.<br>
 </p>
 <p>
     <b>3.</b> When ASK price reached (Stop price) 570, we will close this order and get 5% profit.<br>
 </p>
 <p>
     <b>4.</b> When ASK price is 510 (that is going behind the Trailing Take Profit 540, now the profit% is 15), then the Stop price will get changed as 540 [600-10% = 540].<br>
 </p>
 <p>
     <b>5.</b> That is when Profit goes high, we will reduce the stop price as well.<br>
 </p>
`;
return msg;
}
export const TaillingTakeProfit = () => {

    let msg = `
    <img src=${trailing_image} alt="Trailing Stop Price Percentage" style="width:100%;height:100%;" />
 <p>
    <b>Ex:</b> Trailing Take Profit Percentage = 10%, Trailing Stop Price Percentage = 5%.<br>
 </p>
 
 <p>
     <b>1.</b> When order entered into position with price as 600, then Trailing Take Profit will be 540 (-10% of 600) & Trailing Stop Price will be 570 (-5% of 600), that is Stop price = 570.<br>
 </p>
 
 <p>
     <b>2.</b>  When ASK price was in the range of 540 to 569, the Stop price will be 570.<br>
 </p>
 <p>
     <b>3.</b> When ASK price reached (Stop price) 570, we will close this order and get 5% profit.<br>
 </p>
 <p>
     <b>4.</b> When ASK price is 510 (that is going behind the Trailing Take Profit 540, now the profit% is 15), then the Stop price will get changed as 540 [600-10% = 540].<br>
 </p>
 <p>
     <b>5.</b> That is when Profit goes high, we will reduce the stop price as well.<br>
 </p>
`;
return msg;
}

export const MidPrize = () => {

    let msg = `
<p>
    This field is to calculate the price for the limit order.
</p>
<p>This helps create limit orders with prices close to the current market price. If the value is 0 or empty, then the limit price will be the mid value of best bid and best ask. otherwise it will use the following method for limit price</p>
<p>
    <b>Ex:</b> If you entered 75% here, then the calculation for price as follows.<br>
</p>

<p>
    <b>for sell order-> </b> if the best bid is 100 and the best ask is 300, then the mid value is 200
    Difference between current best ask and mid value is 100 (300-200), 75% of value 100 is 75. so limit order price= 200 (mid price) + 75
    So the sell price will be 275.<br>
</p>

<p>
    <b>for buy order -> </b> Difference between mid value and current best bid is 100 (200-100), 75% of value 100 is 75. so limit order price= 200 (mid price) - 75, So the buy price will be 125.<br>
</p>

`;
return msg;
}

export const CredentialsSetupMSG = (exchange) => {
    let msg;
    console.log(exchange)
    if (exchange == "hitbtc"){
        msg = `<p>1.Log in to your <a href="https://hitbtc.com/">HitBtc account or create a new one</a>.</p>
        <p>2. Go to the Settings page and select Api Key tab</p>
        <p>3. Click the <b>Create New API Key</b> button on the top right side of the page.</p>
        <p>4. Copy your API key and Secret key.</p>
        <p>NOTE: The API Key will only display one time, please make sure to copy and save it carefully.</p>
        <p>5. After saving the keys, click the <b>I Understand</b> button</p>
        <p>6. Give your API key any name (it is your personal choice).</p>
        <p>7. Now you need to Edit acess rights on your API key so our platform receives access to process your trading activity on your HitBtc account.</p>
        <p>8. Add an Derisnap IP (195.201.241.185) to the whitelist.</p>
        <p>9. Click submit for creating Api Key.</p>
        <p>10. Now you need to confirm the key creation by entering the two factor authentication code. Enter the code and click the <b>Confirm</b> button.</p>`
    }
    if (exchange == "phemex"){
        msg = `<p>1. Log in to the correspondent domain and hover over your Account name on the top right corner of the page.</p>
        <p>2. Click on <b>API Management</b>.</p>
        <p>3. Click the <b>Create New API Key</b> button on the top right side of the page.</p>
        <p>4. Choose either High Rate Limit API entry or Default API entry.</p>
        <p>5. Enter a Name and IP address.</p>
        <p>6. Select the desired level of Permission and click <b>Confirm</b>.</p>
        <p>7. Enter the Email Verification Code sent to you by Phemex.</p>
        <p>NOTE: The API Key will only display one time, please make sure to copy and save it carefully.</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/6Mmm6kQ-Ifs?si=5QB7zhWGLqzqsT8e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    }
    if (exchange == "coinbase"){
        msg = `<p>1. Log into <a href="https://portal.cdp.coinbase.com/">Coinbase Developer Platform</a> (CDP).</p>
        <p>2. Navigate to <a href="https://portal.cdp.coinbase.com/access/api?keyType=trade&referrer=advanced_trade">Settings > API Keys</a>.</p>
        <p>3. In the <b>Create API</b> key dialog, configure:</p>
        <ul><li>API key nickname.</li>
        <li>Portfolio (e.g., Default).</li>
        <li>Permission level (View, Trade, Transfer).</li>
        <li>Allowlist IPs.</li></ul>
        <p>4. Click <b>Create & Download</b>.</p>
        <p>5. Click <b>Complete 2-factor authentication</b> and enter your CDP 2FA code. The key automatically downloads as a JSON file.</p>
        <p>6. In the final popup, you can optionally copy the <code>API Key Name</code> and <code>Private Key</code> (which are also in the JSON download). Read the warning and click <b>Got it</b> to finish.</p>
        <p>Please take note of the IP access restrictions. You need to add our IP (195.201.241.185).</p>
        <img src=${CPT} alt="1" border="0" width="450px"><br><br>`
    }
    if (exchange == "binance_us_paper_trade"){
        msg = `<p>1. Log in to the <a target="_blank" href="https://testnet.binance.vision/">Binance Spot Test Network website</a> by clicking [Log In with GitHub].</p>
        <img src=${BPT} alt="1" border="0" width="450px"><br><br>
        <p>2. You'll be redirected to the GitHub website. Log in with your account, or click [Create an account] if you don’t have a GitHub account yet.</p>
        <img src=${BPT2} alt="1" border="0" width="450px"><br><br>
        <p>3. Click [Authorize binance-exchange] to authorize Binance Spot Testnet.</p>
        <img src=${BPT3} alt="1" border="0" width="450px"><br><br>
        <p>4. You will be redirected back to the Spot Test Network page. Here you can see your testing API Keys.</p>
        <img src=${BPT4} alt="1" border="0" width="450px"><br><br>
        <p>If you don’t have any API Keys, click [Generate HMAC_SHA256 Key] and follow the instructions to create one. Please keep your Secret Key secure as it will not be shown again. Do not share this key with anyone. If you forget your Secret Key, you will need to click [Revoke] to delete the API and create a new one.</p>
        <img src=${BPT5} alt="1" border="0" width="450px"><br><br>
        `;
    }
    if (exchange == "bybit"){
        msg = `<p><b>1. Go to Bybit</b></p>
        <p> Log in or create a new account at <a target="_blank" href="https://www.bybit.com/">https://www.bybit.com/</a>.</p>
        <p><b>2. Open the API dashboard.</b></p>
        <p> Hover over the profile icon on the top right corner, then click API:</p>
        <img src=${BYBIT1} alt="1" border="0" width="450px"><br><br>
        <p><b>3. Click Create New Key</b></p>
        <img src=${BYBIT2} alt="1" border="0" width="450px"><br><br>
        <p><b>Note! You must enable 2FA to be able to add API keys.</b></p>
        <p>If you see this dialog, click <b>Confirm</b>:</p>
        <img src=${BYBIT3} alt="1" border="0" width="450px"><br><br>
        <p>Click <b>Settings</b> and follow Bybit's instructions to enable 2FA:</p>
        <img src=${BYBIT4} alt="1" border="0" width="450px"><br><br>
        <p><b>4. Choose the System-genetated API Keys</b></p>
        <img src=${BYBIT5} alt="1" border="0" width="450px"><br><br>
        <p>Choose API Transaction and enter name for the API key.</p>
        <img src=${BYBIT6} alt="1" border="0" width="450px"><br><br>
        <p><b>5. Select permissions and click Submit </b></p>
        <p>You need to select the following permissions:</p>
        <li>Read-Write option;</li>
        <li>Orders and Positions in the Contract section;</li>
        <li>Trade in the Unified Trading section (needs to be checked in any way);</li>
        <li>Trade in the SPOT section.</li>
        <img src=${BYBIT7} alt="1" border="0" width="450px"><br><br>
        <p>These permissions are responsible for the following markets:</p>
        <li>Spot = Spot market.</li><br>
        <p><b>6. Copy API Key and API Secret somewhere safe</b></p>
        <img src=${BYBIT8} alt="1" border="0" width="450px"><br><br>
        <p>Click on Understood after copy-pasting the API keys.</p>
        `;
    }
    if (exchange == "binance") {
        msg = `<p>1. Log in to your Binance.US account.</p>
        <img src=${BNB1} alt="1" border="0" width="450px"><br><br>
        <p>2. Enter a label/name for your API key and click [Create API].</p>
        <p>Security tip: Before creating an API, you need to enable two-factor authentication (2FA) on your account.</p>
        <img src=${BNB2} alt="1" border="0" width="450px"><br><br>
        <p>3. Complete the security verification with your registered 2FA devices.</p>
        <img src=${BNB3} alt="1" border="0" width="450px"><br><br>
        <p>4. Your API is now created. Please keep your Secret Key securely as will not be shown again. Do not share this key with anyone. If you forget your Secret Key, you will need to delete the API and create a new one.</p>
        <p>Please take note of the IP access restrictions. You need to add our IP (195.201.241.185).</p>
        <p>Please take note of the API restrictions. You need to Enable Reading and Enable Spot & margin Trading.</p>
        <img src=${BNB4} alt="1" border="0" width="450px"><br><br>
        <p>To remain compliant with the latest industry requirements, Binance is updating its API services. Effective from 2021-08-09 03:00 (UTC), only users who have completed intermediate verification can create new API keys. For accounts that have only completed basic verification, any existing API keys will be deactivated after 2021-08-23 00:00 AM (UTC). For more information, please refer to the announcement.</p>
        `;
    }
    if (exchange == "binanceus") {
        msg = `<p>1. After logging into your Binance account, click [API Management] from the user center icon.</p>
        <p>2. On the upper navigation menu, click API Management on the dropdown menu that appears when you hover over your email.</p>
        <img src=${BNBUS1} alt="1" border="0" width="450px"><br><br>
        <p>Please note you must complete Basic Verification to access the API Management feature.<p>
        <p>3. Enter a label for your API Key. Your label should be as descriptive as possible, so you can return to the API Management feature and easily locate and manage your API Keys.</p>
        <img src=${BNBUS2} alt="1" border="0" width="450px"><br><br>
        <p>4. Complete two-factor authentication (2FA) to proceed and confirm the confirmation email. To create an API, you may need to verify both your chosen two-factor authentication method (SMS or Authenticator app) and email.</p>
        <img src=${BNBUS3} alt="1" border="0" width="450px"><br><br>
        <p>5. View your API Key and click Copy or scan the QR code. For your security, your API Secret Key will only be displayed at the time it is created.</p>
        <p>If you lose this key, you will need to delete your API and set up a new one. Please carefully review the API restrictions sections and only grant API key access to trusted entities. By default, the API restrictions are set to Enable Read, which allows trusted entities to access your transaction history.</p>
        <p>Finally, you can also configure your IP access restrictions to trusted IPs that you access Binance.US from. </p>
        `;
    }
    if (exchange == "delta") {
        msg = `<p>1. On Delta Exchange, click the profile icon located on the header.</p>
        <img src=${apiimage} alt="1" border="0"><br><br>
        <p>2. Select “API Keys”.</p>
        <p>3. Enter a name for the keys, and Our IP (195.201.241.185) in the whitelisted IP address field. This is to ensure that delta exchange recognises the orders sent from Our servers.</p>
        <p>4. Permit to "Read Data" and "Trading" Then Click on “Create New Key.”</p>
        <img src=${apiimage1} alt="1" border="0" width="450px"><br><br>
        <p>5. Copy the generated API Key and Secret.</p>`; 
    }
    return msg;

}

export const Deltahedgingmsg = () => {
    let msg = `
    <h4>Note:</h4>
    <p> Delta hedging is a risk management strategy that aims to reduce or eliminate the potential losses associated with price movements in an underlying asset. This is achieved by taking offsetting positions in the market.</P>
    <p> Enabling delta hedging individually for each trading strategy, such as strangles, diagonals, and straddles, means that you are applying this risk management technique separately for each of these strategies. For instance, if you have both a strangle strategy and a diagonal strategy, you would manage the risk for each of these strategies independently. </P>
    <p> On the other hand, enabling account level delta hedging means that you are applying this risk management technique to your entire account. Rather than managing the risk for each strategy individually, you are managing the risk for your entire portfolio of strategies. This could be advantageous if you have a diverse portfolio and want to manage your overall risk, rather than the risk associated with each individual strategy</P>
    `
    return msg;
}